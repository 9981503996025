<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h2 class="mt-2">特定商取引法に基づく表記</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="py-2">
                <v-list-item-content>事業者名: 株式会社ランダムファクトリー</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>運営責任者: 伊奈守道</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>Webサイト: sukisuki.live</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content
                  >住所: 東京都渋谷区道玄坂1丁目10番8号渋谷道玄坂東急ビル2F−C</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>電話番号: 03 - 6774 - 7991</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content class="custom-content">
                  メールアドレス: support@sukisuki.live
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content
                  >販売価格: 各イベントページの記載の価格にて販売</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い方法: クレジットカード、あと払い（ペイディ）が利用できます
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い時期・方法:
                  <div class="py-2">
                    クレジットカード払い:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日にお支払いといたします。
                  </div>
                  <div class="py-2">PayPay: 購入手続き完了時に即時決済いたします。。</div>
                  <div class="py-2">
                    あと払い（ペイディ）:
                    毎月請求確定分を月末締めで翌月1日に請求書を発行し、5日までにEメール・SMS（ショートメッセージ）にてご案内いたします。<br />
                    支払方法は、コンビニ払い（コンビニ設置端末）、銀行振込及び口座振替となります。<br />
                    支払期日は、コンビニ払い及び銀行振込の場合は27日までとなります。口座振替の場合は27日に引き落しとなります。<br />
                    支払方法により、毎月のお支払（請求）毎に手数料が発生いたします。コンビニ払いの場合最大390円（税込）、銀行振込の場合は振込手数料をお客様にご負担いただきます。口座振替の場合、支払手数料は発生いたしません。<br />
                    *金融機関休業日の場合は、翌営業日
                  </div>
                  <!-- <div>
                    スマートフォンキャリア決済:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日に携帯料金と合算でのお支払いとなります。
                  </div> -->
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  商品の引渡し時期: ご注文を受けてから45日以内に発送いたします。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  初期不良の場合に限り返品を受け付けます。なお、返送の際の送料は弊社負担といたします。重複注文・イメージ違い・サイズが合わないなど、お客さま都合でのキャンセル・交換・返品は承っておりません。
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.custom-content {
  line-height: 1.4; /* これにより行の高さが増加します */
}
</style>
